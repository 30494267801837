var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: !_vm.settings.isMobileView
        ? _vm.$style.desktopProfileMenuContainer
        : ""
    },
    [
      _c(
        "a-dropdown",
        {
          attrs: {
            trigger: ["click"],
            placement: "bottomRight",
            overlayClassName: _vm.$style.ProfileMenu
          }
        },
        [
          _c("div", { class: _vm.$style.dropdown }, [
            _vm.settings.isMobileView
              ? _c(
                  "div",
                  [
                    _c("a-avatar", {
                      class: _vm.$style.avatarMobile,
                      attrs: { shape: "square", icon: "user", size: 28 }
                    }),
                    _c("a-icon", {
                      class: _vm.$style.dropIconMobile,
                      attrs: { type: "down" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.settings.isMobileView
              ? _c(
                  "span",
                  [
                    _c("a-avatar", {
                      class: _vm.$style.avatar,
                      attrs: { shape: "square", icon: "user", size: 28 }
                    }),
                    _c("span", { class: _vm.$style.nameInTopbar }, [
                      _vm._v(_vm._s(_vm.name))
                    ]),
                    _c("a-icon", {
                      class: _vm.$style.dropIcon,
                      attrs: { type: "down-circle" }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "a-menu",
            { attrs: { slot: "overlay" }, slot: "overlay" },
            [
              _c("a-menu-item", { class: _vm.$style.nameTag }, [
                _c("div", { class: _vm.$style.versionText }, [
                  _vm._v("Ver. 4.2.22")
                ]),
                _c("div", { class: _vm.$style.nameText }, [
                  _vm._v(_vm._s(_vm.name))
                ])
              ]),
              _c("a-menu-divider"),
              _c(
                "a-menu-item",
                { class: _vm.$style.profileMenuItem },
                [
                  _c("router-link", { attrs: { to: { name: "2faShow" } } }, [
                    _c("i", {
                      staticClass: "icmn-qrcode mr-2",
                      class: _vm.$style.menuIcon
                    }),
                    _c("span", [_vm._v("2段階認証設定")])
                  ])
                ],
                1
              ),
              _c("a-menu-item", { class: _vm.$style.profileMenuItem }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://tayori.com/q/aicontact-support/",
                      target: "_BLANK"
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-question-circle-o mr-2" }),
                    _vm._v("\n          ヘルプ\n          "),
                    _c("i", { staticClass: "fa fa-external-link" })
                  ]
                )
              ]),
              _c("a-menu-item", { class: _vm.$style.profileMenuItem }, [
                _c("a", { on: { click: _vm.emailChangeRequest } }, [
                  _c("i", {
                    staticClass: "icmn-envelop",
                    class: _vm.$style.menuIcon
                  }),
                  _vm._v(" メールアドレス変更\n        ")
                ])
              ]),
              _c("a-menu-item", { class: _vm.$style.profileMenuItem }, [
                _c("a", { on: { click: _vm.passwordChangeRequest } }, [
                  _c("i", {
                    staticClass: "icmn-lock",
                    class: _vm.$style.menuIcon
                  }),
                  _vm._v(" パスワード変更\n        ")
                ])
              ]),
              _c("a-menu-item", { class: _vm.$style.profileMenuItem }, [
                _c("a", { on: { click: _vm.logout } }, [
                  _c("i", {
                    staticClass: "icmn-exit",
                    class: _vm.$style.menuIcon
                  }),
                  _vm._v(" Logout\n        ")
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "パスワード変更",
            visible: _vm.modalPass.visible,
            "confirm-loading": _vm.modalPass.confirmLoading
          },
          on: { ok: _vm.passHandleOk, cancel: _vm.passHandleCancel }
        },
        [_c("p", [_vm._v(_vm._s(_vm.modalPass.modalText))])]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "メールアドレス変更",
            visible: _vm.modalEmail.visible,
            "confirm-loading": _vm.modalEmail.confirmLoading
          },
          on: { ok: _vm.emailHandleOk, cancel: _vm.emailHandleCancel }
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: { layout: "vertical", model: _vm.form, rules: _vm.rules }
            },
            [
              _c(
                "a-form-model-item",
                {
                  ref: "email",
                  attrs: { label: "新規メールアドレス", prop: "email" }
                },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.email.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }